
.glyphicon.normal-right-spinner {
  -webkit-animation: glyphicon-spin-r 2s infinite linear;
  animation: glyphicon-spin-r 2s infinite linear;
}


@-webkit-keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

@keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

.rdtInput {
  float: none;
}

.bd-bar, .bd-bar a {
  background-color: #409 !important;
  color: white !important;
}

.wagz2-bar, .wagz2-bar a {
  background-color: #2c99ce !important;
  color: white !important;
}

.petzi-bar, .petzi-bar a {
  background-color: fuchsia !important;
  color: white !important;
}

